import React from "react";
import image from "../../../img/custom.svg";
import css from "./Calculator.module.scss";
import { Button } from "../../components/Core";

const Custom = () => {
  return (
    <div class={css.custom}>
      <div class={css.customImage}>
        <img src={image} alt="" />
      </div>
      <div class={css.customInfo}>
        <h3>Řešení na míru</h3>
        <p>Pomůžeme vám najít to správné řešení z naší široké nabídky.</p>
        <p>
          Nová tiskárna již od <span>99 Kč</span> / měsíc
          <br />
          od <b>0,11 Kč</b> za černobílý výtisk
          <br />
          od <b>0,55 Kč</b> za barevný výtisk
        </p>
        <Button as="link" url="/poptavka">
          Nezávazně poptat
        </Button>
      </div>
    </div>
  );
};

export default Custom;
