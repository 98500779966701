import React from "react";
import css from "./Cta.module.scss";
import { Container, Background, Button } from "../../components/Core";

const Cta = () => {
  return (
    <Background color="blue">
      <Container>
        <div className={css.cta}>
          <h2>Připraveni na tisk budoucnosti?</h2>
          <p>
            Snižte své náklady až o 40 %. Rádi vám poskytneme více informací a
            připravíme nabídku na míru. Nezávazně a bezplatně.
          </p>
          <Button as="link" url="/poptavka">
            Nezávazně poptat
          </Button>
        </div>
      </Container>
    </Background>
  );
};

export default Cta;
