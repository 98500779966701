import React from "react";
import { Helmet } from "react-helmet";
import "../sass/master.scss";
import Topbar from "../sections/Topbar/Topbar";
import Hero from "../sections/Hero/Hero";
import Points from "../sections/Points/Points";
import Calculator from "../sections/Calculator/Calculator";
import Printer from "../sections/Printer/Printer";
import Cta from "../sections/Cta/Cta";
import Footer from "../sections/Footer/Footer";
import ogimage from "../../img/hero.png";

export default function index() {
  return (
    <>
      <Helmet>
        <html lang="cs" />
        <title>Firemní tiskárny bez starostí</title>
        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <meta
          name="description"
          content="Automatické dodávky spotřebního materiálu. Kompletní servis bez starostí. Objevte kouzlo pronajatých tiskáren. Nabídka ušitá na míru potřebám vaší firmy."
        />
        <meta name="google" content="notranslate" />
        <meta property="og:url" content="https://bezstarosti.vseprotisk.cz" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Firemní tiskárny bez starostí" />
        <meta property="og:image" content={ogimage} />
        <meta
          property="og:description"
          content="Automatické dodávky spotřebního materiálu. Kompletní servis bez starostí. Objevte kouzlo pronajatých tiskáren. Nabídka ušitá na míru potřebám vaší firmy."
        />
        <meta property="og:site_name" content="bezstarosti.vseprotisk.cz" />
        <meta property="og:locale" content="cs_CZ" />
        <meta name="twitter:site" content="@vseprotisk" />
        <meta name="twitter:url" content="https://bezstarosti.vseprotisk.cz" />
        <meta name="twitter:title" content="Firemní tiskárny bez starostí" />
        <meta
          name="twitter:description"
          content="Automatické dodávky spotřebního materiálu. Kompletní servis bez starostí. Objevte kouzlo pronajatých tiskáren. Nabídka ušitá na míru potřebám vaší firmy."
        />
        <meta name="twitter:image" content={ogimage} />
        <meta name="google-site-verification" content="GXo6IbZumpEUZk7Vo9T-NctewrMAN0TthHrDLVj1fZY" />
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NSHS6PW');`}</script>
      </Helmet>
      <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NSHS6PW" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      <header>
        <Topbar />
        <Hero />
      </header>
      <main>
        <Points />
        <Calculator />
        <Printer />
        <Cta />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}
