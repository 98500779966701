import React from "react";

const Info = ({ dataset, css, selected }) => {
  return (
    <div className={css.info}>
      <h3>{dataset.name}</h3>
      <div className={css.infoMonth}>
        <span>{dataset.month} Kč</span>zaplatíte měsíčně
      </div>
      <div className={css.infoPage}>
        <span>{dataset.black} Kč</span> za černobílou stránku
      </div>
      <div
        className={`${css.infoPage} ${css.infoPageColor}`}
        style={selected === 1 ? { visibility: "hidden" } : {}}
      >
        <span>{dataset.color} Kč</span> za barevnou stránku
      </div>
    </div>
  );
};

export default Info;
