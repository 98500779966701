import React, { useState } from "react";
import css from "./Calculator.module.scss";
import data from "../../../content/printers.json";
import { Background, Container } from "../../components/Core";
import Switch from "./Switch";
import Info from "./Info";
import Counter from "./Counter";
import Custom from "./Custom";
import imgOne from "../../../img/sol1.jpg";
import imgTwo from "../../../img/sol2.jpg";
import imgThree from "../../../img/sol3.jpg";

const Calculator = () => {
  const [selected, setSelected] = useState(4);
  const handleSelect = (e) => {
    setSelected(parseInt(e.target.dataset.num, 10));
  };
  let img;
  let dataset;
  switch (selected) {
    case 1:
      dataset = data.one;
      img = imgOne;
      break;
    case 2:
      dataset = data.two;
      img = imgTwo;
      break;
    case 3:
      dataset = data.three;
      img = imgThree;
      break;
    default:
      dataset = data.two;
      img = imgTwo;
      break;
  }
  return (
    <Background>
      <Container>
        <div className={css.calculator} id="priklady">
          <div className={css.heading}>
            <h2>Příklady řešení</h2>
            <p>
              Žádné nečekané náklady vás nepřekvapí. Platíte jen za to, co vytisknete. S naším softwarem máte navíc aktuální přehled a kontrolu nad tiskem ve
              vaší firmě.
            </p>
          </div>
          <Switch css={css} selected={selected} handle={handleSelect} />
          <div className={css.box}>
            {selected < 4 ? <div className={css.image} style={{ backgroundImage: `url(${img})` }}></div> : null}
            {selected < 4 ? (
              <div className={css.content}>
                <Info css={css} dataset={dataset} selected={selected} />
                <Counter css={css} dataset={dataset} selected={selected} />
              </div>
            ) : (
              <Custom></Custom>
            )}
          </div>
        </div>
      </Container>
    </Background>
  );
};
export default Calculator;
