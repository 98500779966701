import React from "react";
import css from "./Printer.module.scss";
import { Container } from "../../components/Core";
import check from "../../../img/check.svg";
const Point = ({ children }) => {
  return (
    <div className={css.point}>
      <img src={check} alt="checkmark" />
      <span>{children}</span>
    </div>
  );
};
const Printer = () => {
  return (
    <Container>
      <div className={css.printer}>
        <div className={css.heading}>
          <h2>Vyšší produktivita díky moderní technologii</h2>
        </div>
        <div className={css.points}>
          <Point>Jednoduché ovládání</Point>
          <Point>Spolehlivý provoz</Point>
          <Point>Originální spotřební materiál</Point>
          <Point>Bezkonkurenční rychlost tisku</Point>
        </div>
      </div>
    </Container>
  );
};

export default Printer;
