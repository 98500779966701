import React from "react";
import css from "./Points.module.scss";
import { Container } from "../../components/Core";
import truck from "../../../img/truck.svg";
import activity from "../../../img/activity.svg";
import chat from "../../../img/chat.svg";
import cog from "../../../img/cog.svg";
const Point = ({ icon, children }) => {
  return (
    <div className={css.point}>
      <img src={icon} alt={children} />
      <span>{children}</span>
    </div>
  );
};
const Points = () => {
  return (
    <Container>
      <section className={css.points}>
        <div className={css.content}>
          <h2>Zbavte se starostí s tiskárnami</h2>
          <p>
            <strong>S pronájmem firemní tiskárny se nemusíte o nic starat.</strong>
            &nbsp;Netíží vás počáteční investice ani docházející barvy v tiskárně. O vaši kancelář se postaráme my, vy se tak můžete věnovat svému podnikání.
          </p>
        </div>
        <div className={css.pointsGrid}>
          <Point icon={truck}>Automatické dodávky spotřebního materiálu</Point>
          <Point icon={activity}>Nonstop monitoring tiskáren</Point>
          <Point icon={chat}>Profesionální technická podpora</Point>
          <Point icon={cog}>Kompletní servis zdarma</Point>
        </div>
      </section>
    </Container>
  );
};

export default Points;
