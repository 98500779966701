import React, { useState, useEffect } from "react";
import { Slider, Button } from "../../components/Core";
import { Popover, PopoverBody } from "shards-react";

const Counter = ({ css, dataset, selected }) => {
  const [slider, setSlider] = useState({
    black: 2500,
    color: 500,
  });
  const [result, setResult] = useState(0);
  const [pop, setPop] = useState(false);
  const open = () => {
    setPop(!pop);
  };
  const handleSliders = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    setSlider((state) => ({ ...state, [id]: value }));
  };
  const compute = () => {
    let good = dataset.color * slider.color + dataset.black * slider.black;
    let bad = dataset.anticolor * slider.color + dataset.antiblack * slider.black;
    let compare = bad - good;
    let resultvalue = compare * 12;
    setResult(Math.round(resultvalue));
  };
  useEffect(() => {
    compute();
  });
  return (
    <div className={css.counter}>
      <span>Měsíčně vytisknete:</span>
      <Slider min="500" max="6000" step="100" name="black" id="black" value={slider.black} handle={handleSliders} text="černobílých stran" />
      <Slider
        min="0"
        max="6000"
        step="100"
        name="color"
        id="color"
        value={selected === 1 ? 0 : slider.color}
        handle={handleSliders}
        text="barevných stran"
        style={selected === 1 ? { visibility: "hidden" } : {}}
      />
      <div className={css.result}>
        <div className={css.resultNumber} style={result > 0 ? { display: "none" } : { lineHeight: "1.3", fontWeight: "bold" }}>
          Pro tento objem tisku vám rádi nabídneme jiné
          <b style={{ color: "#05996f" }}> řešení na míru</b>.
        </div>
        <div className={css.resultNumber} style={result <= 0 ? { display: "none" } : {}}>
          <span>
            {result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Kč
            <button aria-label="Podrobnosti" id="popbut" className={css.popbut} onClick={open}>
              {" "}
            </button>
            <Popover placement="right" open={pop} toggle={open} target="#popbut">
              <PopoverBody className={css.pop}>
                K porovnání jsme použili průměrnou cenu tisku za námi nejčastěji nahrazovaná zařízení:
                <div className={css.popBlack}>
                  <b>{dataset.antiblack} Kč</b> / černobílý výtisk
                </div>
                <div className={css.popColor} style={selected === 1 ? { visibility: "hidden" } : {}}>
                  <b>{dataset.anticolor} Kč</b> / barevný výtisk
                </div>
              </PopoverBody>
            </Popover>
          </span>
          roční úspora tiskových nákladů
        </div>
        <div className={css.resultCta}>
          <Button as="link" url="/poptavka">
            Nezávazně poptat
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Counter;
