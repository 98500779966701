import React from "react";
import css from "./Hero.module.scss";
import { Container, Button } from "../../components/Core";
import hp from "../../../img/hp-logo.svg";
import brother from "../../../img/brother-logo.svg";
import epson from "../../../img/epson-logo.svg";
import kyocera from "../../../img/kyocera-logo.svg";
import oki from "../../../img/oki-logo.svg";
export default function Hero() {
  return (
    <Container className={css.heroContainer}>
      <div className={css.hero}>
        <div className={css.content}>
          <h1>Ušetřete čas i peníze díky tiskárnám na&nbsp;míru</h1>
          <p>Zapomeňte na všední i nevšední starosti s firemním tiskem. Vybereme pro vás vhodné tiskárny a kompletně se o ně postaráme. Vy už jen tisknete.</p>
        </div>
        <div className={css.cta}>
          <Button as="link" url="/poptavka">
            Nezávazně poptat
          </Button>
          <Button as="a" url="#priklady" color="ghost">
            Příklady řešení
          </Button>
        </div>
      </div>

    </Container>
  );
}
