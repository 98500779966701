import React from "react";

const Switch = ({ handle, selected, css }) => {
  return (
    <div className={css.switch}>
      <button className={selected === 1 ? css.active : ""} onClick={handle} data-num="1" id="sw1">
        Černobílá tiskárna
      </button>
      <button className={selected === 2 ? css.active : ""} onClick={handle} data-num="2" id="sw2">
        Barevná multifunkce
      </button>
      <button className={selected === 3 ? css.active : ""} onClick={handle} data-num="3" id="sw3">
        Barevná A3 multifunkce
      </button>
      <button className={selected === 4 ? css.active : ""} onClick={handle} data-num="4" id="sw4">
        Řešení na míru
      </button>
    </div>
  );
};

export default Switch;
